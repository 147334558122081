<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Secretaria</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Secretaria
                  <span>Virtual</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12">
                  <section class="fd-mode-opcoes">
                    <!-- Dashboard -->
                    <div class="fd-mode-opcoes-item position-relative">
                      <div class="row">
                        <a
                          class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/secretaria/dashboard')
                          "
                        >
                          <img
                            :src="require('@/assets/images/app/matriz-icon.png')"
                            class="img-fluid"
                          >
                          <h3 class="menu_font_color">Dashboard da secretaria</h3>
                        </a>
                      </div>
                    </div>
                    <!-- Kanban -->
                    <!-- <div class="fd-mode-opcoes-item position-relative">
                      <div class="row">
                        <a
                          class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/secretaria/kanban')
                          "
                        >
                          <img
                            :src="require('@/assets/images/app/menu_kanban_icon.png')"
                            class="img-fluid"
                          >
                          <h3 class="menu_font_color">Kanban</h3>
                        </a>
                      </div>
                    </div> -->
                    <!-- Configurar -->
                    <div                      
                      class="fd-mode-opcoes-item position-relative"
                      data-toggle="collapse"
                      href="#collapseBoxPersonalizar"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseBoxPersonalizar"
                      dont-toogle-lg
                    >
                      <div class="row align-items-center">
                        <a                          
                          class="col-sm-12 col-md-12 col-lg-8"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                        >
                          <div
                            class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded-top"
                          >
                            <img
                              :src="require('@/assets/images/app/personalizar-icon.png')"
                              class="img-fluid"
                            >
                            <h3 class="menu_font_color">Configurar</h3>
                          </div>
                        </a>
                        <div class="col-sm-12 col-md-12 col-lg-8">
                          <b-navbar
                            toggleable="sm"
                            type="dark"
                            variant="secondary"
                            class="rounded-bottom"
                          >
                            <b-navbar-toggle target="nav-collapse" />

                            <b-collapse
                              id="nav-collapse"
                              is-nav
                            >                            
                              <b-navbar-nav
                                class="ml-auto"
                              >  
                                <b-nav-item
                                  href="#"
                                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/disciplinas')"
                                >
                                  <span class="text-white"><b-icon-book /> Disciplinas</span>                                  
                                </b-nav-item>
                                <b-nav-item
                                  href="#"
                                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/base-bibliografica')"
                                >
                                  <span class="text-white"><b-icon-book /> Bibliografias</span>                                  
                                </b-nav-item>
                                <b-nav-item
                                  href="#"
                                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/planos')"
                                >
                                  <span class="text-white"><b-icon-credit-card /> Pagamentos</span>                                  
                                </b-nav-item>
                                <b-nav-item
                                  href="#"
                                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/avaliacoes')"
                                  class="d-none"
                                >
                                  <span class="text-white"><b-icon-check2-square /> Cadastro de avaliação</span>                                  
                                </b-nav-item>
                                
                              </b-navbar-nav>

                              <b-navbar-nav
                                class="ml-auto"                                
                              > 
                                <b-nav-item-dropdown right>
                                  <template #button-content>
                                    <span class="text-white"><b-icon-kanban /> Aulas</span>
                                  </template>
                                  <b-dropdown-item
                                    href="#"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/salas')"
                                  >
                                    Tipos de sala
                                  </b-dropdown-item>      
                                  <b-dropdown-item
                                    href="#"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/feriados')"
                                  >
                                    Calendário escolar
                                  </b-dropdown-item>   
                                </b-nav-item-dropdown>

                                <b-nav-item-dropdown right>
                                  <template #button-content>
                                    <span class="text-white"><b-icon-file-earmark-text /> Documentos</span>
                                  </template>
                                  <b-dropdown-item
                                    href="#"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/template-composicao')"
                                  >
                                    Template de composição
                                  </b-dropdown-item>                              
                                  <b-dropdown-item
                                    href="#"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/contratos')"
                                  >
                                    Templates de contrato
                                  </b-dropdown-item>                              
                                  <b-dropdown-item
                                    href="#"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/documentos')"
                                  >
                                    Tipos de documento
                                  </b-dropdown-item>    
                                  <b-dropdown-item
                                    href="#"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/categorias-autenticacao')"
                                  >
                                    Categorias de autenticação
                                  </b-dropdown-item>    
                                </b-nav-item-dropdown>

                                <b-nav-item-dropdown right>
                                  <template #button-content>
                                    <span class="text-white"><b-icon-clipboard-data /> Registros</span>
                                  </template>
                                  <b-dropdown-item
                                    href="#"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/requerimentos')"
                                  >
                                    Tipos de requerimento
                                  </b-dropdown-item>
                                  <b-dropdown-item
                                    href="#"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/ocorrencias')"
                                  >
                                    Tipos de ocorrência
                                  </b-dropdown-item>
                                  <b-dropdown-item
                                    href="#"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/resultados')"
                                  >
                                    Tipos de resultado
                                  </b-dropdown-item>
                                  <b-dropdown-item
                                    href="#"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/dispensas')"
                                  >
                                    Tipos de dispensa
                                  </b-dropdown-item>
                                  <b-dropdown-item
                                    href="#"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/justificativas')"
                                  >
                                    Tipos de justificativa de falta
                                  </b-dropdown-item>
                                  <b-dropdown-item
                                    href="#"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/saidas')"
                                  >
                                    Tipos de saída
                                  </b-dropdown-item>
                                </b-nav-item-dropdown>
                              </b-navbar-nav>
                            </b-collapse>
                          </b-navbar>

                          <ul class="fd-mode-opcoes-item-menu-list d-none">
                            <li class="fd-mode-opcoes-item-menu-list-item-multi d-flex">
                              <a
                                class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/contratos')"
                              >Salas</a>
                              <a
                                class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/contratos')"
                              >Resultados</a>
                              <a
                                class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/contratos')"
                              >Saídas</a>
                              <a
                                class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/contratos')"
                              >Justificativas</a>
                              <a
                                class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/contratos')"
                              >Dispensas</a>
                              <a
                                class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/contratos')"
                              >Feriados</a>
                            </li>
                            <li class="fd-mode-opcoes-item-menu-list-item-multi d-flex">
                              <a
                                class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/documentos')"
                              >Documentos</a>
                              <a
                                class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/contratos')"
                              >Contratos</a>
                              <a
                                class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/planos')"
                              >Pagamento</a>
                              <a
                                class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/base-bibliografica')"
                              >Bibliografia</a>
                            </li>                            
                            <li class="fd-mode-opcoes-item-menu-list-item-multi d-flex">                            
                              <a
                                class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/requerimentos')"
                              >Requerimentos</a>                       
                              <a
                                class="menu_font_color con-classroom-anotacoes-desativado shadow rounded"
                                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/ocorrencias')"
                              >Ocorrências</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Documentos -->
                    <div class="fd-mode-opcoes-item position-relative d-none">
                      <div class="row">
                        <a
                          class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/secretaria/documentos')
                          "
                        >
                          <img
                            :src="
                              require('@/assets/images/app/documents-icon.png')
                            "
                            class="img-fluid"
                          >
                          <h3 class="menu_font_color">Documentos</h3>
                        </a>
                      </div>
                    </div>     
                    <!-- Contratos -->
                    <div class="fd-mode-opcoes-item position-relative d-none">
                      <div class="row">
                        <a
                          class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/secretaria/contratos')
                          "
                        >
                          <img
                            :src="
                              require('@/assets/images/app/documents-icon.png')
                            "
                            class="img-fluid"
                          >
                          <h3 class="menu_font_color">Templates de contratos</h3>
                        </a>
                      </div>
                    </div> 
                    <!-- Base curricular -->
                    <div class="fd-mode-opcoes-item position-relative">
                      <div class="row">
                        <a
                          class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/secretaria/base-curricular')
                          "
                        >
                          <img
                            :src="
                              require('@/assets/images/app/database.png')
                            "
                            class="img-fluid"
                            style="max-width: 112px"
                          >
                          <h3 class="menu_font_color">Base curricular</h3>
                        </a>
                      </div>
                    </div>    
                    <!-- Unidade curricular -->
                    <div class="fd-mode-opcoes-item position-relative">
                      <div class="row">
                        <a
                          class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/secretaria/unidades-curriculares')
                          "
                        >
                          <img
                            :src="
                              require('@/assets/images/app/cursos-icon.png')
                            "
                            class="img-fluid"
                            style="max-width: 112px"
                          >
                          <h3 class="menu_font_color">Unidades curriculares</h3>
                        </a>
                      </div>
                    </div>  
                    <!-- Cursos -->
                    <div class="fd-mode-opcoes-item position-relative">
                      <div class="row">
                        <a
                          class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/secretaria/cursos')
                          "
                        >
                          <img
                            :src="
                              require('@/assets/images/app/ondemand-icon.png')
                            "
                            class="img-fluid"
                          >
                          <h3 class="menu_font_color">Cursos</h3>
                        </a>
                      </div>
                    </div>                
                    <!-- Turmas -->
                    <div class="fd-mode-opcoes-item position-relative">
                      <div class="row">
                        <a
                          class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/secretaria/turmas')
                          "
                        >
                          <img
                            :src="
                              require('@/assets/images/app/certificados-icon.png')
                            "
                            class="img-fluid"
                          >
                          <h3 class="menu_font_color">Turmas</h3>
                        </a>
                      </div>
                    </div>
                    <!-- alunos -->
                    <div
                      class="fd-mode-opcoes-item position-relative"
                      href="#"
                    >
                      <div class="row align-items-center">
                        <a
                          class="col-sm-12 col-md-12 col-lg-8 con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"  
                          @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/admin/alunos?secretaria')
                          "                   
                        >
                          <div
                            class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                          >
                            <img
                              :src="require('@/assets/images/graduates.png')"
                              class="img-fluid"
                              style="max-width: 112px"
                            >
                            <h3 class="menu_font_color">Alunos</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <!-- professores -->
                    <div
                      class="fd-mode-opcoes-item position-relative"
                      href="#"
                    >
                      <div class="row align-items-center">
                        <a
                          class="col-sm-12 col-md-12 col-lg-8 con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"  
                          @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/admin/professores?secretaria')
                          "                   
                        >
                          <div
                            class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                          >
                            <img
                              :src="require('@/assets/images/teacher.png')"
                              class="img-fluid"
                              style="max-width: 112px"
                            >
                            <h3 class="menu_font_color">Professores</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <!-- Matrículas -->
                    <div class="fd-mode-opcoes-item position-relative">
                      <div class="row">
                        <a
                          class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/secretaria/solicitacoes-matricula')
                          "
                        >
                          <img
                            :src="
                              require('@/assets/images/app/matriculas.png')
                            "
                            class="img-fluid"
                            style="max-width: 112px"
                          >
                          <h3 class="menu_font_color">Solicitações de matrícula</h3>
                        </a>
                      </div>
                    </div>
                    <!-- Documentos -->
                    <div
                      class="fd-mode-opcoes-item position-relative"
                      href="#"
                    >
                      <div class="row align-items-center">
                        <a
                          class="col-sm-12 col-md-12 col-lg-8 con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"  
                          @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/secretaria/assinatura-de-documentos')
                          "                   
                        >
                          <div
                            class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content"
                          >
                            <img
                              :src="require('@/assets/images/app/menu_integracoes_icon.png')"
                              class="img-fluid"
                              style="max-width: 112px"
                            >
                            <h3 class="menu_font_color">Documentos autenticados</h3>
                          </div>
                        </a>
                      </div>
                    </div>
                    <!-- Certificador -->
                    <div class="fd-mode-opcoes-item position-relative">
                      <div class="row">
                        <a
                          class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/secretaria/certificadores')
                          "
                        >
                          <img
                            :src="
                              require('@/assets/images/app/certificador-icon.png')
                            "
                            class="img-fluid"
                          >
                          <h3 class="menu_font_color">Certificador</h3>
                        </a>
                      </div>
                    </div>
                    <!-- Ingresso -->
                    <div class="fd-mode-opcoes-item position-relative d-none">
                      <div class="row">
                        <a
                          class="col-sm-12 col-md-12 col-lg-8 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                          @click.prevent="$router.push(
                            '/plataforma/' +
                              $route.params.id_plataforma +
                              '/secretaria/ingressos')
                          "
                        >
                          <img
                            :src="
                              require('@/assets/images/app/matriz-icon.png')
                            "
                            class="img-fluid"
                          >
                          <h3 class="menu_font_color">Configurações de ingresso</h3>
                        </a>
                      </div>
                    </div> 
                    <!-- Relatórios -->
                    <div                      
                      class="fd-mode-opcoes-item position-relative"
                      data-toggle="collapse"
                      href="#collapseBoxPersonalizar"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseBoxPersonalizar"
                      dont-toogle-lg
                    >
                      <div class="row align-items-center">
                        <a                          
                          class="col-sm-12 col-md-12 col-lg-8"
                          :style="{
                            backgroundColor: $store.state.fastPersonalizacao
                              .menu_background_color
                              ? $store.state.fastPersonalizacao
                                .menu_background_color
                              : '#fff',
                          }"
                        >
                          <div
                            class="col-sm-12 col-md-12 col-lg-12 fd-mode-opcoes-item-content con-classroom-anotacoes-desativado shadow rounded-top"
                          >
                            <img
                              :src="require('@/assets/images/app/responsive.png')"
                              class="img-fluid mr-2"
                              style="max-width: 115px"
                            >
                            <h3 class="menu_font_color">Relatórios</h3>
                          </div>
                        </a>
                        <div class="col-sm-12 col-md-12 col-lg-8">
                          <b-navbar
                            toggleable="sm"
                            type="dark"
                            variant="secondary"
                            class="rounded-bottom"
                          >
                            <b-navbar-toggle target="nav-collapse" />

                            <b-collapse
                              id="nav-collapse"
                              is-nav
                            >
                              <b-navbar-nav
                                class="ml-auto"
                                fill
                              >                                
                                <b-nav-item
                                  href="#"
                                  class="pt-1"
                                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/relatorios-avaliacoes')"
                                >
                                  <span class="text-white"><b-icon-file-earmark-medical /> Avaliações alunos</span>                                  
                                </b-nav-item>
                                <b-nav-item
                                  href="#"
                                  class="pt-1"
                                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/relatorios-requisicoes-alunos')"
                                >
                                  <span class="text-white"><b-icon-file-earmark-person /> Requisições alunos</span>                                  
                                </b-nav-item>
                                                                
                              </b-navbar-nav>
                            </b-collapse>
                          </b-navbar>
                        </div>
                      </div>
                    </div>                     
                  </section>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow text-danger"
                  @click.prevent="$router.push(
                    '/plataforma/' + $route.params.id_plataforma)
                  "
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoSecretaria",
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.$store.state.fastCarregando = false;
            this.$store.state.fastControlaComponentes.showHideExcluiDocumentoAutenticacao = true;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {},
};
</script>

<style scope>
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
